import React, { ComponentType } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * Disable button.
   */
  disabled?: boolean;
  /**
   * Render button without background, border and padding.
   */
  simple?: boolean;
  /**
   * Render button as another component or tag
   */
  as?: ComponentType<any>;
  /**
   * React ref
   */
  ref?: React.RefObject<any>;
}

const Button = React.forwardRef<any, ButtonProps>(
  ({ as: tag, disabled, simple, className, children, ...props }, ref) => {
    return (
      <StyledButton
        as={tag}
        disabled={disabled}
        ref={ref}
        className={clsx('button', { disabled, simple }, className)}
        {...props}>
        {children}
      </StyledButton>
    );
  }
);

export default React.memo(Button);

const StyledButton = styled.button`
  background: ${props => props.theme.colors.primary};
  box-sizing: border-box;
  border: 2px solid ${props => props.theme.colors.primary};
  color: white;
  fill: white;
  padding: 12px 30px;
  border-radius: 100px;
  font-size: 18px;
  line-height: 24px;
  transition: border 200ms, background 200ms, color 200ms, fill 200ms;
  cursor: pointer;

  &.simple {
    padding: 0;
    border: none;
    background: none;
    color: #1e1e1e;

    &:hover {
      background: none;
      opacity: 0.7;
    }
  }

  &:hover,
  &:active {
    background: ${props => props.theme.colors.primaryAccent};
  }

  &.disabled {
    pointer-events: none;
    fill: ${props => props.theme.colors.darkGray};
    color: ${props => props.theme.colors.darkGray};
    background: ${props => props.theme.colors.lightGray};
    border-color: ${props => props.theme.colors.lightGray};
    .icon {
      fill: ${props => props.theme.colors.darkGray};
    }
  }
  &:focus {
    outline: 0;
  }
`;
