import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

import Icon from '../Icon';

interface MessageBoxProps {
  children: React.ReactNode;
  type?: 'success' | 'error';
}

const MessageBox: React.FC<MessageBoxProps> = ({ children, type }) => {
  const iconName = type === 'error' ? 'close' : 'check';
  return (
    <StyledMessageBox className={clsx('message', type)}>
      <div className="circle">
        <Icon name={iconName} />
      </div>
      {children}
    </StyledMessageBox>
  );
};

export default MessageBox;

const StyledMessageBox = styled.div`
  margin: 150px auto;
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 34px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.red};
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
  &.success {
    .circle {
      border: 1px solid ${props => props.theme.colors.green};
    }
    .icon {
      fill: ${props => props.theme.colors.green};
    }
  }
  &.error {
    .circle {
      border: 1px solid ${props => props.theme.colors.red};
    }
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
  a {
    margin-top: 10px;
    display: block;
    color: ${props => props.theme.colors.red};
    text-decoration: none;
  }
`;