import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { encodeJson, encodeQueryParams, JsonParam } from 'use-query-params';

import { Icon, PopMenu, PopMenuProps } from '@xchange/uikit';
import Button from 'components/Button';
import Input from 'components/Input';
import { REGEXP } from 'consts';
import { signIn } from 'api';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';

interface LoginMenuProps extends PopMenuProps {
  onClose?: () => void;
}

const LoginMenu: React.FC<LoginMenuProps> = ({ onClose, ...props }) => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const submit = async values => {
    try {
      const { tokens, redirectURL } = await signIn(values);
      const authData = {
        accessToken: tokens.access_token,
        refreshToken: tokens.refresh_token,
        expiresIn: tokens.expires_in,
        tokenType: tokens.token_type,
        admin: tokens.admin
      };
      window.open(`${redirectURL}auth?auth=${encodeJson(authData)}`);
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledLoginMenu className="login-menu" {...props}>
      <Button onClick={onClose} className="login-menu__btn-close" simple>
        <Icon name="mini-x" size={12} />
      </Button>
      <h4 className="login-menu__title">Login to your MyBrokerTools space:</h4>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          {...register('email', {
            required: 'Required',
            pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
          })}
          placeholder="Email"
          error={errors.email}
          type="email"
        />
        <Input
          {...register('password', { required: 'Required' })}
          placeholder="Password"
          error={errors.password}
          type="password"
        />
        <Button>Log In</Button>
        <br />
        <button className="btn-forgot">Forgot password?</button>
      </form>
    </StyledLoginMenu>
  );
};

export default LoginMenu;

const StyledLoginMenu = styled(PopMenu)`
  width: 420px;
  padding: 16px 30px;

  .login-menu {
    &__btn-close {
      fill: ${props => props.theme.colors.primary};
      float: right;
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 8px 0 32px 0;
    }
  }

  form {
    text-align: center;

    .input {
      margin-bottom: 12px;
      text-align: left;
    }

    .button {
      margin: 18px;
      width: 150px;
    }

    .btn-forgot {
      font-size: 16px;
      line-height: 24px;
      color: #7f7f7f;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 300px;
  }
`;
