import React, { useCallback, useEffect, useState } from 'react';
import { Modifier, Placement, PositioningStrategy } from '@popperjs/core';
import { usePopper } from 'react-popper';
import clsx from 'clsx';

import styled from '@emotion/styled';
// import useOutsideClick from 'hooks/useOutsideClick';

export interface PopMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  target: any;
  open?: boolean;
  placement?: Placement;
  strategy?: PositioningStrategy;
  modifiers?: Partial<Modifier<unknown, object>>[];
  onOutsideClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  outsideClickNoTarget?: boolean;
  children?: React.ReactNode;
  unmountChildren?: boolean;
}

const defaultModifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top', 'right'] } },
  { name: 'preventOverflow', options: { padding: 10 } },
  { name: 'offset', options: { offset: [0, 15] } }
];

const PopMenu: React.FC<PopMenuProps> = ({
  children,
  target: referenceElement,
  placement = 'bottom',
  strategy,
  modifiers,
  open = false,
  unmountChildren,
  onOutsideClick,
  outsideClickNoTarget = true,
  className,
  style,
  ...props
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement,
    strategy,
    modifiers: modifiers || defaultModifiers
  });

  useEffect(() => {
    if (open && forceUpdate) forceUpdate();
  }, [forceUpdate, open]);

  const handleClick = useCallback(
    (e: any) => {
      if (outsideClickNoTarget && referenceElement?.contains(e.target)) return;
      if (!popperElement?.contains(e.target)) onOutsideClick?.(e);
    },
    [popperElement]
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);
    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [handleClick]);

  return (
    <StyledPopperContent
      ref={setPopperElement as any}
      className={clsx({ open }, className)}
      style={{ ...styles.popper, ...style }}
      {...attributes.popper}
      {...props}>
      {unmountChildren && !open ? null : children}
    </StyledPopperContent>
  );
};

export default React.memo(PopMenu);

const StyledPopperContent = styled.div`
  z-index: 1000;
  background: white;
  padding: 8px 12px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 3px;

  &:not(.open) {
    display: none;
  }
`;
