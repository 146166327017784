import React from 'react';
import styled from '@emotion/styled';

interface ToggleButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  label,
  onChange,
  ...inputProps
}) => {
  return (
    <StyledToggleButton className="toggle-button">
      <input
        type="checkbox"
        id="toggle-input"
        className="toggle-input"
        onChange={onChange}
        {...inputProps}
      />
      {label && (
        <label htmlFor="toggle-input" className="toggle-label">{label}</label>
      )}
    </StyledToggleButton>
  );
};

export default ToggleButton;

const StyledToggleButton = styled.div`
  .toggle-input {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 24px;
    margin: 0;
    vertical-align: top;
    background: ${props => props.theme.colors.graphite};
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 2px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      transform: translateX(0);
      transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:before {
      content: "OFF";
      position: absolute;
      left: 23px;
      font-size: 12px;
      line-height: 24px;
      color: #858585;
    }
    &:checked::after {
      transform: translateX(calc(100% + 8px));
      background-color: #fff;  
    }
    &:checked::before {
      content: "ON";
      left: 4px;
      color: #fff; 
    }
    &:checked {
      background-color: ${props => props.theme.colors.red};
    }
  }
  label {
    font-size: 12px;
    vertical-align: middle;
    line-height: 24px;
    margin-left: 10px;
  }
`;