/* eslint-disable camelcase */

import { urlEncodeBody } from 'utils/requests';

const apiUrl = process.env.GATSBY_API_URL;

function FetchException(this: any, response: Response, responseData?: any) {
  this.message = response.statusText;
  this.status = response.status;
  this.response = responseData;
}

function tryParseJSON(json: string): Record<string, unknown> | null {
  if (!json) return null;

  try {
    return JSON.parse(json);
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`);
  }
}

async function handleError(response: Response) {
  const data = tryParseJSON(await response.clone().text());
  throw new FetchException(response, data);
}

export type FormValues = {
  name: string;
  email: string;
  email_to?: string;
  phone?: string;
  rolesignup?: string;
  numtransactions?: string;
  subject?: string;
  body?: string;
};

export async function sendEmail(values: FormValues): Promise<void> {
  const body = new URLSearchParams();
  Object.entries(values).forEach(([key, value]) => value && body.set(key, value));

  const response = await fetch(`${apiUrl}/send_email`, { method: 'POST', body });

  if (!response.ok) {
    if (response.status === 429) throw Error('Please try in one minute');
    else throw Error(await response.text());
  }
}

export async function signIn(values: {
  email: string;
  password: string;
}): Promise<{
  redirectURL: string;
  tokens: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: 'Bearer';
    admin: boolean;
  };
}> {
  const body = urlEncodeBody(values);
  const response = await fetch(`${apiUrl}/sign-in`, { method: 'POST', body });
  if (!response.ok) return handleError(response) as any;
  return response.json();
}
