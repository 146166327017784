import React, { useRef, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Icon, SideMenu } from '@xchange/uikit';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import LoginMenu from 'components/LoginMenu';

interface HeaderProps {
  className?: string;
  border?: boolean;
  isLight?: boolean;
  onClickRequest: () => void;
  onClickContact: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isLight,
  border,
  className,
  onClickRequest,
  onClickContact
}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `);

  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const layout = useLayout();
  const loginButtonRef = useRef(null);

  function onClickCall(){
    window.open("tel:+17733288070");
  }

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
      case 'tablet':
        return (
          <>
            <StyledHeader className={clsx('header', { light: isLight, border }, className)}>
              <div className="header__logo-block">
                <Link to="/" className="header__logo">
                  <img src={data.logo.publicURL} width="200px" height="30px" alt="logo" />
                </Link>
              </div>
              <button onClick={() => setOpenMenuModal(true)} className="header__menu-trigger">
                <Icon name="burger-menu" />
              </button>
            </StyledHeader>
            <StyledSideMenu open={openMenuModal} onClose={() => setOpenMenuModal(false)}>
              <Link to="/" className="side-menu__logo">
                <img src={data.logo.publicURL} width="160px" height="24px" alt="logo" />
              </Link>

              <Button className="side-menu__item demo-btn">
                <Link to="/agents">
                  Agents
                </Link>
              </Button>
              <Button className="side-menu__item demo-btn">
                <Link to="/brokerages">
                  Managing Brokers
                </Link>
              </Button>
   
              <Button className="side-menu__item demo-btn" onClick={onClickCall}>
                (773) 328-8070
              </Button>
              <Button
                ref={loginButtonRef}
                className="side-menu__item"
                onClick={() => setOpenAuthModal(v => !v)}>
                Sign In
              </Button>
              <LoginMenu
                open={openAuthModal}
                target={loginButtonRef.current}
                placement="bottom-start"
                onClose={() => setOpenAuthModal(false)}
              />
            </StyledSideMenu>
          </>
        );
      default:
        return (
          <StyledHeader className={clsx('header', { light: isLight, border }, className)}>
            <div className="header__logo-block">
              <Link to="/" className="header__logo" data-cy="header_logo">
                <img src={data.logo.publicURL} width="200px" height="30px" alt="logo" />
              </Link>
            </div>
            <div className="header__nav">
              
              <Button simple>
                <Link to="/agents">
                  Agents
                </Link>
              </Button>
              <Button simple>
                <Link to="/brokerages">
                  Managing Brokers
                </Link>
              </Button>
              <Button className="demo-btn" onClick={onClickCall}>
                (773) 328-8070
              </Button>
              <Button
                ref={loginButtonRef}
                className="login-btn"
                onClick={() => setOpenAuthModal(v => !v)}>
                Sign In
              </Button>
            </div>
            <LoginMenu
              open={openAuthModal}
              target={loginButtonRef.current}
              placement="bottom-end"
              onClose={() => setOpenAuthModal(false)}
            />
          </StyledHeader>
        );
    }
  };

  return getLayout();
};

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  position: relative;
  padding: 16px 60px;
  box-sizing: border-box;
  margin: auto;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid transparent;

  .header__nav a{text-decoration: none;
    color: #1e1e1e;padding-right:20px;}

  &.border {
    border-color: ${props => props.theme.colors.borderColor};

    .header__nav .demo-btn {
      background: ${props => props.theme.colors.primary};
      color: white;
    }
  }

  .header {
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
    }

    

    &__menu-trigger {
      background: none;
      border: none;
      width: 32px;
      height: 32px;
    }

    &__nav {
      .demo-btn {
        margin-left: 0px;
        background: white;
        border: 1px solid ${props => props.theme.colors.borderColor};
        color: initial;
      }
      .login-btn {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 16px;
  }
`;

const StyledSideMenu = styled(SideMenu)`
  display: flex;
  flex-direction: column;
  .side-menu {
    &__logo {
      display: inline-block;
      margin-bottom: 20px;
    }
    &__item {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: black;
      text-decoration: none;
      margin: 0 0 23px;
      text-align: unset;
      border: none;
      background: none;
      padding: 0;
    }
    a{
      color: black;
    text-decoration: none;
    }
  }
`;

export default Header;
