import styled from '@emotion/styled';
import { Checkbox as UikitCheckbox } from '@xchange/uikit';

const Checkbox = styled(UikitCheckbox)`
  label {
    font-size: 16px;
  }

  input:checked {
    background: ${props => props.theme.colors.primary};
  }
`;

export default Checkbox;
