import React from 'react';

import styled from '@emotion/styled';

interface NotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'success' | 'error';
}

const Notification: React.FC<NotificationProps> = ({ type, children }) => {
  return <StyledNotification className={type}>{children}</StyledNotification>;
};

export default Notification;

const StyledNotification = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  background: #000000c4;
  color: #fff;
  fill: #fff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  width: 464px;
  min-height: 36px;
  max-width: 100vw;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
`;
