import { useMediaPredicate } from 'react-media-hook';
import { breakpoints } from 'styles/theme.json';

export default function useLayout() {
  const mobile = useMediaPredicate(`(max-width: ${breakpoints.sm})`);
  const tablet = useMediaPredicate(`(max-width: ${breakpoints.md})`);

  if (mobile) return 'mobile';
  if (tablet) return 'tablet';
  return 'desktop';
}
