import React from 'react';
import styled from '@emotion/styled';

/**
 * To get environment vartiables below you need run package.json script with "env-cmd -f ../../git.js" as a prefix
 */

const Version = () => (
    <StyledInput>
        <div className="version-header"> <a href="/">HOME</a> </div>
        <div> git head hash - {process.env.GATSBY_GIT_HEAD}</div>
        <div> git subject - {process.env.GATSBY_GIT_SUBJECT}</div>
        <div> git remote - {process.env.GATSBY_GIT_REMOTE}</div>
        <div> git branch - {process.env.GATSBY_GIT_BRANCH}</div>

    </StyledInput>
)

export default Version

const StyledInput = styled.div`
    margin: 20px;
    .version-header {
        margin-bottom: 10px;
    }
`



