const isEmpty = (value: any) => value !== 0 && !value;

export const tryGetFirstError = (data: any = {}) => {
  if (typeof data === 'string') return data;
  if (typeof data.response === 'string') return data.response;
  if (!data?.response?.errors) return data.message;
  return (Object.values(data.response.errors)[0] as string[])[0];
};

export const createFormData = (data: Record<string, string> = {}) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => !isEmpty(value) && formData.set(key, value));

  return formData;
};

export const urlEncodeBody = (data: Record<string, string> = {}) => {
  const body = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => !isEmpty(value) && body.set(key, value));

  return body;
};
