import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';

const Footer = props => {
  const { t } = useTranslation();

  return (
    <StyledFooter className="footer" {...props}>
      <div className="footer-wrapper">
        <div className="footer__bottom-info">
          <div className="footer__bottom-infо-wrapper">
            <p className="footer__firm-name">{t('footer.company_name')}</p>
            <p className="footer__firm-adress">{t('footer.address')}</p>
            <p className="footer-links">
              <Link className="footer-link" to="/">
                Home
              </Link>{' '}
              <Link className="footer-link" to="/privacy">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.div`
  padding: 85px 16px 139px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #e5e5e5;
  width: 100%;
  max-width: 1246px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;

  .footer {
    &__bottom-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__bottom-infо-wrapper {
      padding: 0 21px 0 0;
    }
    &__firm-name,
    &__firm-adress {
      line-height: 22px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0 0 10px;
    }
    &__description {
      font-size: 12px;
      line-height: 22px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0;
      a {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  .footer-links {
    margin: 0 0 10px;
  }
  .footer-link {
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.colors.darkGray};
    position: relative;
    margin: 0 16px 0 0;
    text-decoration: none;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: -9px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props => props.theme.colors.darkGray};
    }
    &:last-of-type {
      margin: 0;
      &:after {
        display: none;
      }
    }
  }
  .footer-wrapper {
    max-width: 1025px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer-info-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0 0 58px;
  }
  .footer-info-container {
    display: flex;
    .footer-info {
      &:first-of-type {
        padding: 0 30px 0 0;
      }
      &:last-of-type {
        padding: 0 0 0 30px;
      }
    }
  }
  .footer-info {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 18px;
      color: black;
      cursor: default;
    }
    &__links {
      display: flex;
      flex-direction: column;
    }
    &__link {
      font-size: 14px;
      line-height: 22px;
      color: black;
      text-decoration: none;
      margin: 0 0 4px;
    }
    &__link_more {
      font-weight: 500;
      color: ${props => props.theme.colors.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 30px 16px 55px;
    justify-content: flex-start;
    .footer {
      &__bottom-info {
        align-items: flex-start;
        flex-direction: column;
      }
      &__description {
        margin: 0 0 20px;
      }
      &__firm-name {
        font-size: 12px;
        line-height: 22px;
        margin: 0 0 4px;
      }
      &__firm-adress {
        font-size: 12px;
        line-height: 22px;
        margin: 0 0 4px;
      }
    }
    .footer-wrapper {
      max-width: 700px;
    }
    .footer-info-wrapper {
      flex-direction: column;
      margin: 0;
    }
    .footer-info-container {
      flex-direction: column;
      .footer-info {
        &:first-of-type,
        &:last-of-type {
          padding: 0;
        }
      }
    }
    .footer-info {
      &__title {
        font-size: 12px;
        line-height: 24px;
        color: ${props => props.theme.colors.darkGray};
        margin: 0 0 4px;
      }
      &__links {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 20px;
      }
      &__link {
        font-size: 12px;
        line-height: 20px;
        color: ${props => props.theme.colors.darkGray};
        position: relative;
        margin: 0 16px 0 0;
        text-decoration: none;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translate(50%, -50%);
          right: -8px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: ${props => props.theme.colors.darkGray};
        }
        &:last-of-type {
          margin: 0;
          &:after {
            display: none;
          }
        }
      }
      &__link_more {
        font-weight: normal;
        color: ${props => props.theme.colors.darkGray};
        text-transform: uppercase;
      }
    }
    .footer-link {
      margin: 0 16px 0 0;
      font-size: 12px;
      line-height: 20px;
      display: inline-flex;
      &:after {
        right: -8px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 40px 16px 80px;
    .footer {
      &__description {
        margin: 0 0 39px;
      }
      &__bottom-info-wrapper {
        padding: 0 38px 0 0;
      }
    }
    .footer-info {
      &__links {
        margin: 0 0 16px;
      }
    }
    .footer-info-wrapper {
      margin: 0 0 33px;
    }
  }
`;
