import React from 'react';
import styled from '@emotion/styled';

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  size?: number | string;
  className?: string;
}

const CircleSpinner: React.FC<SpinnerProps> = ({ size: sizeProp }) => {
  const size = (sizeProp && { width: sizeProp, height: sizeProp }) || { width: 50, height: 50 };
  return (
    <StyledCircleSpinner className="spinner" viewBox="0 0 50 50" {...size}>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </StyledCircleSpinner>
  );
};

export default React.memo(CircleSpinner);

const StyledCircleSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  z-index: 2;

  & .path {
    stroke: ${props => props.theme.colors.gray};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
