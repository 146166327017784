import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';

import styled from '@emotion/styled';
import Icon from '../Icon';

const AccordionContent = ({ children, focusContent }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const node = contentRef.current;
    if (node && focusContent) node.scrollIntoView({ behavior: 'smooth' });
  }, [focusContent]);

  return (
    <div ref={contentRef} className="accordion-content">
      {children}
    </div>
  );
};

export interface AccordionProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  open?: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  counter?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  unmountOnExit?: boolean;
  onChange?: (value: boolean) => void;
  focusContent?(): any;
}

const Accordion: React.FC<AccordionProps> = ({
  open: openProp,
  defaultOpen = false,
  disabled,
  label,
  counter,
  icon,
  unmountOnExit = true,
  focusContent,
  onChange,
  children,
  className,
  ...rest
}) => {
  const [open, setOpen] = useState(defaultOpen && !disabled);
  const iconComponent = icon || <Icon name="plus-mini" size={10} fill="#000000" />;

  const toggleOpen = () => {
    if (!disabled) {
      onChange && onChange(!open);
      setOpen(open => !open);
    }
  };

  useEffect(() => {
    if (openProp && open !== openProp) setOpen(openProp);
  }, [open, openProp]);

  return (
    <StyledAccordion className={clsx('accordion', { open, disabled }, className)} {...rest}>
      <div onClick={toggleOpen} className="accordion-head">
        {iconComponent}
        <span className="label">{label}</span>
        <span className="counter">{counter}</span>
      </div>

      <CSSTransition
        in={open}
        timeout={{ appear: 200 }}
        classNames="fade"
        unmountOnExit={unmountOnExit}>
        <AccordionContent focusContent={focusContent}>{children}</AccordionContent>
      </CSSTransition>
    </StyledAccordion>
  );
};

export default React.memo(Accordion);

const StyledAccordion = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};
  .accordion-head {
    display: flex;
    align-items: center;
    padding: 20px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    .icon {
      flex-shrink: 0;
      &.icon-plus-mini {
        margin-right: 15px;
        transition: ${props => props.theme.transitions.standart};
      }
    }
    .counter {
      margin-left: 10px;
    }
  }
  &:not(.open) .accordion-content {
    display: none;
  }
  .accordion-content {
    margin-bottom: 20px;
    box-sizing: border-box;
    position: relative;
    &.fade-enter {
      opacity: 0;
      &-done {
        opacity: 1;
        transition: opacity 200ms ease-in;
      }
    }
    &.fade-exit {
      opacity: 1;
      &-done {
        display: none;
        opacity: 0;
        transition: opacity 200ms ease-in;
      }
    }
  }
  &.open .accordion-head .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }
  &.counter .title {
    margin-right: 10px;
  }
  &.disabled .accordion-head {
    cursor: initial;
    .icon {
      fill: #dadada;
    }
    .counter {
      font-weight: normal;
      color: ${props => props.theme.colors.grayDark};
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .accordion-head {
      &:hover {
        background: none;
      }
    }
  }
`;
