import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';

export interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  buttonLike?: boolean;
  label?: React.ReactNode;
  ref?: any;
}

const RadioButton: React.FC<RadioButtonProps> = React.forwardRef(
  ({ id, label, buttonLike, className, ...props }, ref: any) => {
    return (
      <StyledRadioButton
        htmlFor={id}
        className={clsx('radiobutton', className, { 'button-like': buttonLike })}>
        <input ref={ref} type="radio" id={id} {...props} />
        <span className="checkmark" />
        <span className="label">{label}</span>
      </StyledRadioButton>
    );
  }
);

export default RadioButton;

const StyledRadioButton = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
  margin-right: 14px;

  &.button-like {
    .checkmark {
      min-width: 132px;
      padding: 16px 32px;
      border-radius: ${props => props.theme.misc.borderRadius};
      border-color: ${props => props.theme.colors.primary};
    }
    .label {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      color: ${props => props.theme.colors.primary};
      font-weight: 500;
    }
    input:checked {
      ~ .checkmark {
        background: ${props => props.theme.colors.pink};
        border-color: ${props => props.theme.colors.pink};
        &:after {
          display: none;
        }
      }
      ~ .label {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    z-index: -1;
    visibility: hidden;
  }

  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border-radius: 30px;
    border: 1px solid #9c9c9e;
  }

  .label {
    margin-left: 10px;
    user-select: none;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
  }
`;
